const heroSlider = document.querySelector("#hero-slider");
// check to see if el exists first, if so, create the slider
if (heroSlider) {
  new Splide(heroSlider, {
    type: "loop",
    autoplay: true,
    interval: 4000,
    speed: 666,
    arrows: false
  }).mount();
}

const altSlider = document.querySelector("#alt-slider");
// check to see if el exists first, if so, create the slider
if (altSlider) {
  new Splide(altSlider, {
    type: "loop",
    autoplay: true,
    interval: 4000,
    speed: 666,
    arrows: false
  }).mount();
}


const logoSlider = document.querySelector("#logo_slider");
if (logoSlider) {
  var dataAttr = logoSlider.getAttribute("data-slide-number");
  var dataAttrAuto = logoSlider.getAttribute("data-slide-autoplay");
  var dataAttrSpeed = logoSlider.getAttribute("data-slide-speed");
  new Splide(logoSlider, {
    perPage: dataAttr,
    autoplay: dataAttrAuto,
    interval: dataAttrSpeed,
    rewind: true,
    arrowPath:
      "m15.5 0.932-4.3 4.38 14.5 14.6-14.5 14.5 4.3 4.4 14.6-14.6 4.4-4.3-4.4-4.4-14.6-14.6z",
    arrows: false
  }).mount();
}

AOS.init();

/*****
 * language redirect
 * ******/
function getNavigatorLanguage() {
  if (navigator.languages && navigator.languages.length) {
    return navigator.languages[0];
  } else {
    return (
      navigator.userLanguage ||
      navigator.language ||
      navigator.browserLanguage ||
      "en"
    );
  }
}

//set cookie to expire after 1 day
function setlangCookie(cname, cvalue, exdays) {
  var d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  var expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}
//get cookie
function getlangCookie(cname) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}
//check if cookie is set and redirect
function checklangCookie() {
  var cookieIsSet = getlangCookie("lang_redirect");
  if (cookieIsSet != "") {
    // do nothing.
  } else {
    setlangCookie("lang_redirect", "redirected", "1");
    if (getNavigatorLanguage() == "en-US") {
      //window.location.replace("https://novelis.com");
    } else if (
      getNavigatorLanguage() == "de" ||
      getNavigatorLanguage() == "de-AT" ||
      getNavigatorLanguage() == "de-CH" ||
      getNavigatorLanguage() == "de-LU" ||
      getNavigatorLanguage() == "de-LI"
    ) {
      window.location.hostname = "de.novelis.com";
    } else if (
      getNavigatorLanguage() == "zh" ||
      getNavigatorLanguage() == "zh-Hans" ||
      getNavigatorLanguage() == "zh-Hans-CN" ||
      getNavigatorLanguage() == "zh-Hans-HK" ||
      getNavigatorLanguage() == "zh-Hans-MO" ||
      getNavigatorLanguage() == "zh-Hans-SG" ||
      getNavigatorLanguage() == "zh-CN"
    ) {
      window.location.hostname = "zh-hans.novelis.com";
    } else if (getNavigatorLanguage() == "ko") {
      window.location.hostname = "ko.novelis.com";
    } else if (getNavigatorLanguage() == "pt-BR") {
      window.location.hostname = "pt-br.novelis.com";
    } else {
      // do nothing.
    }
  }
}

jQuery(document).ready(function ($) {
  checklangCookie();

  // Function to animate number counting
  function animateNumberCount(element, targetNumber, duration) {
    jQuery({ count: 0 }).animate(
      {
        count: targetNumber
      },
      {
        duration: duration,
        easing: "linear",
        step: function () {
          // Update the text of the element with the current count value
          jQuery(element).text(Math.floor(this.count));
        },
        complete: function () {
          // Set the final value to ensure accuracy
          jQuery(element).text(targetNumber);
        }
      }
    );
  }

  // Intersection Observer setup
  var options = {
    root: null, // use the viewport as the root
    rootMargin: "0px",
    threshold: 0.5 // trigger when 50% of the element is visible
  };

  // Callback function to handle intersection changes
  function handleIntersection(entries, observer) {
    entries.forEach(function (entry) {
      if (entry.isIntersecting) {
        jQuery(".count").each(function () {
          var $this = jQuery(this);
          // Call the function with the target number and duration when the element is visible
          var targetNumber = parseInt($this.attr("data-count"));
          console.log(targetNumber);
          animateNumberCount($this, targetNumber, 1000);
        });
        observer.unobserve(entry.target); // Stop observing once the animation is triggered
      }
    });
  }

  // Create the Intersection Observer
  var observer = new IntersectionObserver(handleIntersection, options);

  // Target the elements with the class .counter-container
  jQuery(".acf-stats-simple--stat-wrapper").each(function () {
    // Observe each .counter-container element
    observer.observe(this);
  });

  // Language Selection
  // English
  jQuery(".menu-english").on("click", function (e) {
    e.preventDefault();
    if (window.location.hostname != "novelis.com") {
      window.location.hostname = "novelis.com";
    }
  });

  // German
  jQuery(".lang-select-de").on("click", function (e) {
    e.preventDefault();
    if (window.location.hostname != "de.novelis.com") {
      window.location.hostname = "de.novelis.com";
    }
  });
  // Chinese
  jQuery(".lang-select-zh").on("click", function (e) {
    e.preventDefault();
    if (window.location.hostname != "zh-hans.novelis.com") {
      window.location.hostname = "zh-hans.novelis.com";
    }
  });
  // Korean
  jQuery(".lang-select-ko").on("click", function (e) {
    e.preventDefault();
    if (window.location.hostname != "ko.novelis.com") {
      window.location.hostname = "ko.novelis.com";
    }
  });
  // Portugues
  jQuery(".lang-select-pt").on("click", function (e) {
    e.preventDefault();
    if (window.location.hostname != "pt-br.novelis.com") {
      window.location.hostname = "pt-br.novelis.com";
    }
  });

  jQuery(".search-results-grid-item").on("click", function () {
    var linkUrl = jQuery(this).find("a").attr("href");
    var linkTarget = jQuery(this).find("a").attr("target");
    if (typeof linkUrl !== "undefined") {
      window.open(linkUrl, linkTarget);
    }
  });

  $(".progress").each(function(){
  
    var $bar = $(this).find(".bar");
    var $val = $(this).find("span.circlestats--stat-percent");
    var perc = parseInt( $val.text(), 10);
  
    $({p:0}).animate({p:perc}, {
      duration: 3000,
      easing: "swing",
      step: function(p) {
        $bar.css({
          transform: "rotate("+ (45+(p*1.8)) +"deg)", // 100%=180° so: ° = % * 1.8
          // 45 is to add the needed rotation to have the green borders at the bottom
        });
        $val.text(p|0);
      }
    });
  });
});
//console.log('lang:'+ getNavigatorLanguage());
